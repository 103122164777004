<template>
  <demo-block v-if="active !== 2">
    <van-swipe
      :autoplay="3000"
      @change="onChange"
      style="height: 200px"
      ref="swipe"
    >
      <van-swipe-item v-for="(image, index) in images" :key="index">
        <van-image :src="image.img" />
        {{ image.title }}
      </van-swipe-item>
    </van-swipe>
  </demo-block>
  <van-notice-bar
    v-if="errorcode === false"
    left-icon="volume-o"
    text="系统已经开通拉卡拉充值，如果要使用支付宝，请用支付宝扫码进行充值"
  />
  <van-notice-bar
    v-if="errorcode"
    left-icon="volume-o"
    :text="errmsg"
  />
  <van-row v-if="errorcode === false" justify="space-between" align="center" :style="this.$store.state.userinfo.status === '挂失'?'background-color:#700000':''">
        <van-col span="5" align="center">
          <van-image
            class="title-img"
            round
            width="5rem"
            height="5rem"
            :src="headpic"
          />
        </van-col>
        <van-col span="6"  offset="0" align="left">
          <van-row style="margin-bottom: 10px">
            <span color="ff0000" style="font-size: 13px;">{{this.$store.state.userinfo.name + '(' + this.$store.state.userinfo.outid  +')'}}</span>
          </van-row>
          <van-row>
            <span color="#ff0000" style="font-size:13px">{{this.$store.state.userinfo.sumoddfare}}</span>
          </van-row>
         
        </van-col>
        <van-col span="5"  offset="1">
          <van-row>
            <span color="#ff0000" style="font-size:13px">{{this.$store.state.userinfo.dpnames}}</span>
         </van-row>
         <van-row v-if="this.$store.state.userinfo.status === '3' || this.$store.state.userinfo.status === '挂失'">
            <span color="#ff0000" style="font-size:13px">{{this.$store.state.userinfo.status}}</span>
         </van-row>
        </van-col>
        <van-col span="5"  offset="1">
          <van-button style="font-size:30px;margin-right:5px" round :icon="require('/assets/images/qrcode.png')" @click="ShowQrCode"  />
        </van-col>
  </van-row>
  <van-grid  clickable column-num="3">
    <van-grid-item  v-for="(item, index) in menulist1"
      :key="index"
      :url="item.link"
      :icon="item.icon" :text="item.menuname" icon-color="#FF5733" />
  </van-grid>
  <p align="center" style="margin-bottom:50px;">
    <a href="https://beian.miit.gov.cn" id="beian" target="_blank"> {{this.$store.state.beian}}</a>
  </p>
  <br>
  <van-tabbar v-model="active">
    <van-tabbar-item icon="home-o">主页面</van-tabbar-item>
    <van-tabbar-item to="/useroption" icon="home-o">我的</van-tabbar-item>
  </van-tabbar>
    
  <van-dialog
    v-model:show="dialogInScene"
    :title="dialogTitle"
    show-cancel-button
    @confirm = "InScene"
    :lazy-render="false"
  >
    <img class="imgDialog" :src="require('../../../assets/images/elec.png')" />
  </van-dialog>
  <van-popup v-model:show="showQrCode" :style="{ padding: '64px' }">
    <div ref="qrcode" id="qrcode" />
  </van-popup>
  <br>
  
</template>

<script>
  import QRCode from 'qrcodejs2'
  import { requestGetSysInfo, requestGetUserBaseInfo } from '../../api/api'
  import { mapState, mapGetters, mapActions } from 'vuex'
  import { IsBlank, GetBrowserType } from '../../utils/tools/index'
  

import {
  Grid,
  GridItem,
  Image,
  Tabbar,
  TabbarItem,
  Swipe,
  SwipeItem,
  Tag,
  Cell,
  Row,
  Col,
  CouponCell,
  CouponList,
  Popup,
  Icon,
  Dialog,
  Toast,
  NoticeBar,
  ActionBar,
  ActionBarButton,
  ActionBarIcon,
  Button

} from "vant";

export default {
  components: {
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Image.name]: Image,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Cell.name]: Cell,
    [Tag.name]: Tag,
    [Col.name]: Col,
    [Row.name]: Row,
    [CouponCell.name]: CouponCell,
    [CouponList.name]: CouponList,
    [Popup.name]: Popup,
    [Icon.name]: Icon,
    [Dialog.Component.name]: Dialog.Component,
    [Toast.name]: Toast,
    [NoticeBar.name]: NoticeBar,
    [ActionBar.name]: ActionBar,
    [ActionBarButton.name]: ActionBarButton,
    [ActionBarIcon.name]: ActionBarIcon,
    [Button.name]: Button
  },
  i18n: {
    "zh-CN": {
      text: "文字",
      route: "页面导航",
      gutter: "格子间距",
      square: "正方形格子",
      columnNum: "自定义列数",
      customContent: "自定义内容",
      urlRoute: "URL 跳转",
      vueRoute: "路由跳转",
      showBadge: "徽标提示",
      horizontal: "内容横排",
    },
    "en-US": {
      text: "Text",
      route: "Route",
      gutter: "Gutter",
      square: "Square",
      columnNum: "Column Num",
      customContent: "Custom Content",
      urlRoute: "URL",
      vueRoute: "Vue Router",
      showBadge: "Show Badge",
      horizontal: "Horizontal",
    },
  },
  watch: {
    active(val) {
      if (val === 1) {
        setTimeout(() => {
           // this.$router.push( '/useroption' + '?t111=' + new Date().getTime() );
        }, 500);
      }
    },
  },
  computed: {
    ...mapState({
      token: state => state.token,
      isbind: state => state.isbind,
      userinfo: state => state.userinfo,
      accesstoken: state => state.accesstoken,
      openid: state => state.openid,
      typekey: state => state.typekey
    }),
    ...mapGetters([
      'openidex',
      'hasopenid',
      'isReAuthLogin',
      'isGrid'
    ])
  },
  data() {
    return {
      errorcode: true,
      errmsg: '加载中...',
      headpic: '',
      showQrCode: false,
      loadcontent: '加载中...',
      isLoading: false,
      dialogIcon: '',
      dialogTitle: '进入模式',
      chartindex: 0,
      dialogInScene: false,
      showScene: false,
      active: 0,
      charttitle: "用水信息",
      chosenCoupon: -1,
      exchangedCoupons: [],
      images: [],
      paytype: 0,
      menulist: [
        {menuid: 0, menuname: '拉卡拉充值', icon: 'wechat', link: '#/pay', isshow: false, functionname: 'pay_lakala'},
        {menuid: 1, menuname: '充值订单', icon: 'wechat', link: '#/payrecent', isshow: false, functionname: 'wxsavedetail'},
        {menuid: 2, menuname: '查询消费', icon: 'after-sale', link: '#/consume', isshow: false, functionname: 'consume'},
        {menuid: 3, menuname: '查询充值', icon: 'after-sale',link: '#/save', isshow: false, functionname: 'save'},
        {menuid: 4, menuname: '挂失', icon: 'clear', link: '#/loss' , isshow: false, functionname: 'selfloss'},
        {menuid: 5, menuname: '门禁功能', icon: 'lock', link: '#/access', isshow: false, functionname: 'wxremotedoor'},
        {menuid: 6, menuname: '考勤功能', icon: 'location', link: '#/attend', isshow: false, functionname: 'attend'},
        {menuid: 7, menuname: '照片采集', icon: 'location', link: '#/facepic', isshow: false, functionname: 'face_comset'},
        {menuid: 8, menuname: '设置', icon: 'setting', link: '#/useroption', isshow: false, functionname: 'option'},
        {menuid: 9, menuname: '解绑', icon: 'stop-circle-o', isshow: false, functionname: 'unbind'},
        {menuid: 10, menuname: '绑定', icon: 'stop-circle-o', link: '#/bind', isshow: false, functionname: 'bind'},
      ],
      menulist1: []
    };
  },
  
  mounted() {
    var _this = this
    this.paytype = GetBrowserType()
    // 如果有errcode代表是有错误发生了，直接显示错误信息\
    var $errcode = this.$route.query.errcode
    var $errmsg = this.$route.query.errmsg
    console.log('___________________________________________')
      console.log('$errcode', $errcode)
      console.log('$errmsg', $errmsg)
      console.log('___________________________________________')
      console.log(IsBlank($errcode))
    if (IsBlank($errcode) === true) { // 为空
      this.errcode = false
      this.errmsg = '加载完成'
    
      this.errorcode = false
      // 如果accesstoken有信息，那就直接设置进去
      var $accesstoken = this.$route.query.accesstoken
      var $token = this.$route.query.localtoken
      console.log('___________________________________________')
      console.log('$token信息', $token)
      console.log('$accesstoken信息', $accesstoken)
      console.log('___________________________________________')
      if (IsBlank($accesstoken) === false) {
        this.$store.commit('UpdateTokenInfo', $accesstoken)
        console.log('开始跳转主页面')
        setTimeout(() => {
          // window.alert('/index' + '?t1=' + new Date().getTime())
          console.log(this.getLocalLoginInfo())
          _this.$router.push( '/index' + '?t111=' + new Date().getTime() );
          console.log(this.getLocalLoginInfo())
          this.GetSysInfo()
          this.GetBaseUserInfo()
          console.log('跳转完毕')
        }, 300);
        // this.getLocalLoginInfo()
      } else {
        console.log(this.getLocalLoginInfo())
        this.GetSysInfo()
        this.GetBaseUserInfo()
      }
    } else {
      console.log('有错误信息出来了，不用跳转了')
      this.errcode = $errcode
      this.errmsg = $errmsg
    }

  },
  methods: {
    ShowQrCode() {
      this.showQrCode=true
      // if (this.$refs.qrcode === undefined) return
      if (this.$refs.qrcode !== undefined)
      {
        this.$refs.qrcode.innerHTML = ''
      }
     
      setTimeout(() => {
        var qrcode = new QRCode('qrcode', {
        text: this.$store.state.userinfo.outid, // 表示内容，可以是地址或者是文字'55566'或者参数
        colorDark: '#228b22', // 前景色
        colorLight: '#ffffff', // 背景色
        correctLevel: QRCode.CorrectLevel.H // 容错级别
      })
        qrcode.makeCode(this.$store.state.userinfo.outid + '') // 生成另一个新的二维码  
      }, 300);
      
      
    },
    ...mapActions([
        'getLocalLoginInfo',
        'getIsNeedAuthLogin',
        'clearAccessToken',
        'getBaseUserInfo'
      ]),
    async GetBaseUserInfo(){
      const res = await requestGetUserBaseInfo({accesstoken: this.$store.state.accesstoken})
        if (res.return_code === '0')
        {

          this.$store.commit('updateUserInfo', res.data[0])
          this.menulist1 = []
          this.menulist.forEach(i => {
            res.functionlist.forEach(f => {
              if (i.functionname === f.functionname && f.isshow === '1') {
                i.menuname = f.functionmemo
                if(i.menuid === 0) {// 拉卡拉充值
                  if (this.paytype ===0 || this.paytype === 1) {
                    i.menuname = '微信充值'
                  } else if (this.paytype === 2) {
                    i.menuname = '支付宝充值'
                  } else { 
                    console.log('不支持的充值方式')
                    return
                  }
                }
                this.menulist1.push(i)
                return
              }
             });
          });
          this.headpic = res.picserver + res.data[0].outid
          this.$store.state.userinfo.facepic = res.picserver + res.data[0].outid
        } else {
          Toast({
              message: '去登录或者去绑定' ,
              icon: 'fail',
            });
          this.$router.push( '/bind' + '?t1=' + new Date().getTime());
        }
    },
    async GetSysInfo() {
      const param = {accesstoken: this.accesstoken}
      const res  = await requestGetSysInfo(param)
       if (res.return_code === '0') {
        console.log(res)
        this.images = res.bannerinfo
        this.$store.state.beian = res.beian
        document.title = res.systemname[0].dpname + ' 服务平台' 
        // console.log(res)
      } else {
        Toast({
          message: res.return_message ,
          icon: 'fail',
        });
      }
    },
    async CheckBind() {
      // 先检查当前localtoken和accesstoken有没有绑定信息

    },
    ClickMenu (param) {
      console.log(param);
      // Toast(param)
      setTimeout(() => {
        this.$router.push( param +  '?t1=' + new Date().getTime());        
      }, 300);
    },
    beforeClose(action, done) {
      if (action === 'confirm') {
        setTimeout(done, 1000);
      } else {
        done();
      }
    },
    InScene() {
      console.log('ok')
      Toast({
        message: this.dialogTitle + '成功',
        icon: this.dialogIcon,
      });
    },
    onChangeScene(index) {
      this.showScene = false;
      this.chosenCoupon = index;
    },
    randomId(max = 999999) {
      return Math.floor(Math.random() * max) + 1;
    },
    onChange(index) {
      this.current = index;
    }
  },
};
</script>

<style lang="less">
.van-grid {
  display: flex;
  flex-wrap: wrap;
}
.img1 {
  height: 60px;
}
.imgDialog {
  box-sizing: border-box;
  width: 100%;
  padding: 25px 20px 0;
}
</style>
